import { Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TimeFrame } from '../services/models/date-range';
import { StravaRxjsService } from '../services/strava-rxjs.service';


@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit, OnDestroy {
    displayColumns: string[];
    totalDistance = 0;
    timeFrame = TimeFrame;
    activitiesSubscription: Subscription;

    athlete$ = this.stravaRxjs.athlete$.pipe(
        tap(athlete => console.log(athlete)),
        catchError(err => {
            console.log(err ? err : 'error getting athlete');
            return EMPTY
        })
    )

    activitiesByRange$ = this.stravaRxjs.activitiesByRange$.pipe(
        tap(activities => {
            this.totalDistance = 0;
            activities.map(activity => this.totalDistance += +activity.distance_miles)
        })
    );

    constructor(
        private stravaRxjs: StravaRxjsService
    ) { }

    async ngOnInit(): Promise<void> {

        this.displayColumns = ['date', 'distance', 'time', 'pace', 'heartrate'];
        this.activitiesSubscription = this.stravaRxjs.activitiesMax$.subscribe();
    }

    ngOnDestroy(): void {
        this.activitiesSubscription.unsubscribe();
    }

}
