import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy{
  user: CognitoUserInterface | undefined;
  authState: AuthState;
  signedIn = false;
  subscriptions: Subscription[] = [];
  handset: boolean;
  connectedToStrava = false;
  stravaAuthenticated: Boolean = false;

  // use handset mode for all but the largest sizes ie not Web
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Web)
    .pipe(
      map(result => !result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private ref: ChangeDetectorRef,
    private ngZone: NgZone,
    private router: Router,
    private auth: AuthService
    ) {}

    ngOnInit(): void {
      onAuthUIStateChange((authState, authData) => {
        console.log(`onAuthStateChange: State - ${authState}`, authData);

        this.authState = authState;
        this.user = authData as CognitoUserInterface;
        if (authState === AuthState.SignedIn && this.stravaAuthenticated === false) {
            // go to Strava authentication page
            this.ngZone.run(() => { this.router.navigate(['/login']); });
            return;
        }
        this.signedIn = authState === AuthState.SignedIn;

        // If the router hasn't navigated, which is the case if the user isn't logged in, then navigate to the default page.
        if (this.router.navigated === false) {
            this.ngZone.run(() => { this.router.navigate(['/']); });
        }
        this.ref.detectChanges();
      });

      this.subscriptions.push(this.isHandset$.subscribe((result) => {
          this.handset = result;
      }));

      this.subscriptions.push(this.auth.stravaAuthenticated$.subscribe((state) => {
        this.stravaAuthenticated = state;
      }))
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
        return onAuthUIStateChange;
    }

    async conditionallyClose(drawer) {
        if (this.handset) {
            drawer.close();
        }
    }

    stravaConnect() {
        this.auth.checkStravaToken().then(() => {
            this.connectedToStrava = true;
        })
        .catch((e) => {
            console.log('Error validating Strava connection');
            console.log(e);
        });
    }

}
