
<mat-card>
  <mat-card-header  fxLayout="row">
    <app-athlete-header [title]="'Activities'"></app-athlete-header>
    <div fxFlex></div>
    <app-time-frame-selector></app-time-frame-selector>
  </mat-card-header>
  <mat-card-content *ngIf="activitiesByRange$ | async as activities">
    <table mat-table #table [dataSource]="activities">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let row"> {{row.start_date | date : 'short' }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="distance">
        <th mat-header-cell *matHeaderCellDef> Distance  </th>
        <td mat-cell *matCellDef="let row"> {{row.distance_miles | number : '1.2-2'}} <small>mi</small> </td>
        <td mat-footer-cell *matFooterCellDef> {{totalDistance | number : '1.2-2'}} <small>total miles</small></td>
      </ng-container>

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef> Time </th>
        <td mat-cell *matCellDef="let row"> {{row.moving_time * 1000 | date : 'H:mm:ss' : 'UTC'}}  </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="pace">
        <th mat-header-cell *matHeaderCellDef> Pace </th>
        <td mat-cell *matCellDef="let row"> {{row.average_pace | number : '1.2-2'}} <small>min/mi</small> </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="heartrate">
        <th mat-header-cell *matHeaderCellDef> Heart Rate </th>
        <td mat-cell *matCellDef="let row"> {{row.average_heartrate | number : '1.0-0'}} <small>beats/min</small> </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>


