<div *ngIf="useMat">
<mat-card>
    <mat-card-header  fxLayout="row">
      <mat-card-title>Club Activities</mat-card-title>

      <div fxFlex></div>
      <!-- <button mat-button (click)="getYTD()">GET YTD</button> -->
      <button mat-button (click)="downloadCSV()">CSV</button>
      <!-- <button mat-button (click)="editName()">EDIT</button> -->
    </mat-card-header>
    <mat-card-content>
      <table mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="athlete">
            <th mat-header-cell *matHeaderCellDef> Athlete </th>
            <td mat-cell *matCellDef="let row"> {{row.athlete.firstname}} {{row.athlete.lastname}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="start_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.start_date | date : 'short' }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let row"> {{row.name}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="distance">
          <th mat-header-cell *matHeaderCellDef> Distance  </th>
          <td mat-cell *matCellDef="let row"> {{row.distance_miles | number : '1.2-2'}} <small>mi</small> </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef> Time </th>
          <td mat-cell *matCellDef="let row"> {{row.moving_time * 1000 | date : 'H:mm:ss' : 'UTC'}}  </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="pace">
          <th mat-header-cell *matHeaderCellDef> Pace </th>
          <td mat-cell *matCellDef="let row"> {{row.average_pace | number : '1.2-2'}} <small>min/mi</small> </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="elevation">
          <th mat-header-cell *matHeaderCellDef> Elevation </th>
          <td mat-cell *matCellDef="let row"> {{row.total_elevation_gain | number : '1.0-0'}} <small>ft</small> </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayColumns"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons>
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
<div *ngIf="loadingData" class="spinner-location">
    <p-progressSpinner></p-progressSpinner>
</div>
<div *ngIf="loadError" class="spinner-location" class="p-grid p-jc-center p-ai-center vertical-container">
    <div class="p-col-12 p-md-6">
        <p-messages [(value)]="displayMessages" [enableService]="false"></p-messages>
    </div>
</div>
<div *ngIf="usePrime && !loadingData && !loadError">
<p-panel>
    <ng-template  pTemplate="header">
        <div class="p-pr-2 p-text-bold"><h3>Club Activities</h3></div>
        <div><button pButton class="p-button-sm p-button-outlined p-button-rounded" style="padding: 0.1rem; font-size: 0.7rem" (click)="downloadCSV()" label="CSV"></button></div>
        <div class="strava-link p-pl-2">
            <a href="{{stravaUrl}}">View on Strava</a>
        </div>

        <div fxFlex></div>

        <p-dropdown [options]="validYears" [(ngModel)]="selectedYear" optionLabel="name" optionValue="code" (onChange)="changeYear()"></p-dropdown>
    </ng-template>
    <p-tabView>
        <!-- Hide progress bar for now -->
        <!-- <p-tabPanel header="Progress">
                <h5>Progress so far</h5>
                <p-progressBar [value]="progress" mode="determinate" [showValue]=true [style]="{'height': '32px'}"></p-progressBar>
                <p-progressBar [value]="progress" mode="determinate" [showValue]=true></p-progressBar>
        </p-tabPanel> -->
        <p-tabPanel header="Overview">
            <p-table [columns]="matrixColumns" [value]="matrixView" responsiveLayout="scroll" [loading]="loadingData">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 8rem">Charts</th>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td>
                            <button pButton type="button" class="p-button-info" icon="pi pi-chart-bar" (click)="showChart(rowData)"></button>
                        </td>
                        <td *ngFor="let col of columns">
                            {{col.field === "week" ? rowData[col.field] : rowData[col.field] | number : '1.1-1'}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-columns>
                    <tr>
                        <td>
                            <button pButton type="button" class="p-button-primary" icon="pi pi-chart-bar" (click)="showTotalsChart()"></button>
                        </td>
                        <td *ngFor="let col of columns">
                            {{col.field === "week" ? "Totals" : col.totalMiles | number : '1.1-1'}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Weekly">
            <p-table [value]="clubActivities" [multiSortMeta]="multiSortMeta" sortMode="multiple" dataKey="week" rowGroupMode="subheader"
                groupRowsBy="week" styleClass="p-datatable-sm" responsiveLayout="scroll" [loading]="loadingData">
                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th pSortableColumn="week">Week<p-sortIcon field="week"></p-sortIcon></th> -->
                        <th>Week</th>
                        <th>Athlete</th>
                        <!-- <th pSortableColumn="start_date">Date<p-sortIcon field="start_date"></p-sortIcon></th> -->
                        <th>Date</th>
                        <th>Distance</th>
                        <th>Time</th>
                        <th>Pace</th>
                        <th>Elevation</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="groupheader" let-activity let-rowIndex="rowIndex" let-expanded="expanded">
                    <tr>
                        <td colspan="7">
                            <button type="button" pButton pRipple [pRowToggler]="activity" class="p-button-sm p-button-text p-button-rounded p-button-plain" style="width:10px" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" style="vertical-align: middle; height: 20px; width: 11px"></button>
                            <!-- <button type="button" pButton pRipple [pRowToggler]="activity" class="p-button-text p-button-rounded p-button-plain p-mr-2" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" style="vertical-align: middle"></button> -->
                            <span class="p-text-bold p-ml-2">Week {{activity.week}}</span> <span class="p-text-bold p-ml-6"> Distance {{getWeekTotal(activity.week) | number : '1.2-2'}} <small>mi</small></span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-activity>
                    <tr>
                        <td class="p-text-bold">{{activity.week}}</td>
                        <td>{{activity.athlete.firstname}} {{activity.athlete.lastname}}</td>
                        <td>{{activity.start_date | date : 'short' }}</td>
                        <td>{{activity.distance_miles | number : '1.2-2'}} <small>mi</small></td>
                        <td>{{activity.moving_time * 1000 | date : 'H:mm:ss' : 'UTC'}}</td>
                        <td>{{activity.average_pace | number : '1.2-2'}} <small>min/mi</small></td>
                        <td>{{activity.total_elevation_gain | number : '1.0-0'}} <small>ft</small></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    Total miles {{weeklyTotal?.overall.totalMiles | number : '1.2-2'}} <small>mi</small>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>
</p-panel>
</div>

