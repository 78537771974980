import { Component, OnDestroy, OnInit } from '@angular/core';
import { StravaService } from '../services/strava.service';
import { BarSeriesOption, EChartsOption, LineSeriesOption } from 'echarts';
import { StravaRxjsService } from '../services/strava-rxjs.service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

type ECOption = echarts.ComposeOption<
    | BarSeriesOption
    | LineSeriesOption
>;

@Component({
    selector: 'app-run-details',
    templateUrl: './run-details.component.html',
    styleUrls: ['./run-details.component.css']
})
export class RunDetailsComponent implements OnInit, OnDestroy {
    // overviewOptions: EChartsOption<EChartsOption.SeriesBar>;
    // overviewOptions: EChartsOption;
    overviewOptions: ECOption;
    // singleRunOptions: EChartsOption<EChartsOption.SeriesLine>;
    // singleRunOptions: EChartsOption;
    singleRunOptions: ECOption;


    activities: any;
    activitiesSubscription: Subscription;

    athlete$ = this.stravaRxjs.athlete$.pipe(
        catchError(err => {
            console.log(err ? err : 'error getting athlete');
            return EMPTY
        })
    )


    constructor(
        private stravaData: StravaService,
        private stravaRxjs: StravaRxjsService
    ) { }

    ngOnInit(): void {
        this.getStravaActivities();
    }

    ngOnDestroy(): void {
        this.activitiesSubscription.unsubscribe();
    }

    getStravaActivities(): void {
        // this.stravaData.getActivities().then((data) => {
        //     console.log(data);
        //     if (data && Array.isArray(data)) {
        //         this.activities = data;
        //         this.buildStravaOverview();
        //         this.buildStravaLineEChart(data[0]);
        //     }
        // });

        // this.activitiesSubscription = this.stravaRxjs.activitiesMax$.subscribe((data) => {
        this.activitiesSubscription = this.stravaRxjs.activitiesByRange$.subscribe((data) => {
            console.log(data);
            if (data && Array.isArray(data)) {
                this.activities = data;
                this.buildStravaOverview();
                this.buildStravaLineEChart(data[0]);
            }
        });
    }

    buildStravaOverview(): void {
        this.overviewOptions = {
            legend: {},
            tooltip: {},
            dataset: {
                dimensions: ['start_date_local', 'distance_miles'],
                source: this.activities
            },
            xAxis: {
                type: 'time'
            },
            yAxis: {},
            series: [
                {
                    type: 'bar',
                    name: 'Distance',
                    barMinWidth: 4,
                    emphasis: {

                        itemStyle: {
                            // barBorderWidth: 2,
                            // barBorderColor: '#888'
                        }
                    }
                },
            ],
        };
    }

    onStravaChartClick(event): void {
        console.log(event);

        const { data } = event;
        this.buildStravaLineEChart(data);
    }

    buildStravaLineEChart(selectedRun): void {
        if (!selectedRun || !selectedRun.id) {
            return;
        }
        this.stravaData.getStreams(selectedRun.id).then(data => {
            this.singleRunOptions = {
                title: {
                    show: true,
                    text: 'Eric Vaughn',
                    subtext: `${selectedRun.start_date_local}`
                },
                legend: {},
                tooltip: {
                    show: true,
                    trigger: 'axis',
                },
                dataset: {
                    dimensions: ['Time', 'Pace', 'Distance', 'HeartRate'],
                    source: data
                },
                xAxis: {
                    type: 'value',
                    name: 'Minutes',
                    nameLocation: 'middle',
                    nameGap: 30,
                },
                yAxis: [
                    {
                        name: 'Pace min/mile',
                        nameLocation: 'middle',
                        nameGap: 30,
                        position: 'left',
                        type: 'value',
                        min: 0,
                        max: 25,
                        splitNumber: 5
                    },
                    {
                        name: 'HeartRate beets/min',
                        nameLocation: 'middle',
                        nameGap: 45,
                        position: 'right',
                        type: 'value',
                        min: 100,
                        max: 200,
                        splitNumber: 5
                    }
                ],
                series: [
                    {
                        type: 'line',
                        name: 'Pace',
                        symbol: 'none',
                        symbolSize: 2,
                        yAxisIndex: 0,
                        encode: {
                            x: 'Time',
                            y: ['Pace']
                        },
                    },
                    {
                        type: 'line',
                        name: 'HeartRate',
                        symbol: 'none',
                        symbolSize: 2,
                        yAxisIndex: 1,
                        encode: {
                            x: 'Time',
                            y: ['HeartRate']
                        }
                    },
                    {
                        type: 'line',
                        name: 'Distance',
                        symbol: 'none',
                        yAxisIndex: 0,
                        encode: {
                            x: 'Time',
                            y: ['Distance']
                        }
                    }

                ]

            };
        });
    }


}
