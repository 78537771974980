import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { StravaLoginComponent } from './strava-login/strava-login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RunDetailsComponent } from './run-details/run-details.component';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { OverviewComponent } from './overview/overview.component';
import { ClubDetailsComponent } from './club-details/club-details.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessagesModule } from 'primeng/messages';
import { ChartModule } from 'primeng/chart';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';

import { ClubChartsComponent } from './club-charts/club-charts.component';
import { TimeFrameSelectorComponent } from './time-frame-selector/time-frame-selector.component';
import { YearlyTotalComponent } from './yearly-total/yearly-total.component';
import { AthleteHeaderComponent } from './athlete-header/athlete-header.component';

@NgModule({
  declarations: [
    AppComponent,
    StravaLoginComponent,
    NavComponent,
    RunDetailsComponent,
    OverviewComponent,
    ClubDetailsComponent,
    ClubChartsComponent,
    TimeFrameSelectorComponent,
    YearlyTotalComponent,
    AthleteHeaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    AmplifyUIAngularModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    NgxEchartsModule.forRoot({ echarts }),
    TableModule,
    ButtonModule,
    CardModule,
    PanelModule,
    TabViewModule,
    ProgressSpinnerModule,
    ChartModule,
    MessagesModule,
    DynamicDialogModule,
    DropdownModule,
    ProgressBarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
