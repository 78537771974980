import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClubDetailsComponent } from './club-details/club-details.component';
import { OverviewComponent } from './overview/overview.component';
import { RunDetailsComponent } from './run-details/run-details.component';
import { AuthGuard } from './services/auth.guard';
import { StravaLoginComponent } from './strava-login/strava-login.component';
import { YearlyTotalComponent } from './yearly-total/yearly-total.component';

const routes: Routes = [
    { path: 'overview', component: OverviewComponent, canActivate: [AuthGuard] },
    //   { path: 'strava_redirect', component: StravaLoginComponent, canActivate: [AuthGuard] },
    { path: 'run_details', component: RunDetailsComponent, canActivate: [AuthGuard] },
    { path: 'yearly_total', component: YearlyTotalComponent, canActivate: [AuthGuard] },
    { path: 'club_details', component: ClubDetailsComponent, canActivate: [AuthGuard] },
    //   { path: '', pathMatch: 'full', redirectTo: 'club_details' },
    { path: 'login', component: StravaLoginComponent },
    { path: 'strava_redirect', component: StravaLoginComponent },
    { path: '', pathMatch: 'full', redirectTo: 'overview' },
    { path: '**', component: StravaLoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',  enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
