import { Component, Input, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StravaRxjsService } from '../services/strava-rxjs.service';

@Component({
  selector: 'app-athlete-header',
  templateUrl: './athlete-header.component.html',
  styleUrls: ['./athlete-header.component.css']
})
export class AthleteHeaderComponent implements OnInit {
    @Input() title: string;
    stravaUrl = "https://www.strava.com";

    athlete$ = this.stravaRxjs.athlete$.pipe(
        tap(athlete => console.log(athlete)),
        catchError(err => {
            console.log(err ? err : 'error getting athlete');
            return EMPTY
        })
    );



  constructor(private stravaRxjs: StravaRxjsService) { }

  ngOnInit(): void {
  }

}
