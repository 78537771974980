/*
 * Copyright Eric Vaughn All Rights Reserved.
 *
 * SPDX-License-Identifier: Apache-2.0
*/

import axios, { AxiosInstance } from 'axios';
import { Injectable } from '@angular/core';
import { AuthService, User } from './auth.service';
import { Athlete } from './models/athlete';
import { Activity } from './models/activity';

const stravaURL = 'https://www.strava.com/api/v3/';

interface Stream {
    type?: string;
    data: number[];
    series_type: 'distance' | 'time';
    original_size: number;
    resolution: 'low' | 'medium' | 'high';
}

type Streams = Stream[];

interface StravaError {
    errors: {
        code: string,
        field: string,
        resource: string
    }[];
    message: string;
}

interface GetActivitiesParams {
    before?: number;
    after?: number;
    page?: number;
    per_page?: number;
}

@Injectable({
    providedIn: 'root'
})
export class StravaService {

    constructor(
        private auth: AuthService
    ) { }

    async getAxiosInstance(): Promise <AxiosInstance> {
        await this.auth.checkStravaToken();
        const token = this.auth.getToken('access_token');
        const request = axios.create({
            baseURL: stravaURL,
            headers: { Authorization: 'Bearer ' + token }
        });
        return request;
    }

    async getAthlete(): Promise<Athlete> {
        console.log('getProfile...');
        try {
            const request = await this.getAxiosInstance();
            const res = await request.get('/athlete');
            console.log(res.data);
            return res.data;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getClubId(clubName: string): Promise<number> {
        console.log('getClubId...');
        const request = await this.getAxiosInstance();
        const results = await request('athlete/clubs');
        const club = results.data.find(c => c.name === clubName);
        return club.id;
    }

    async getClubActivities(clubId: number, page?: number): Promise<Activity[]> {
        console.log('getClubActivities...');
        const params = { page: page, per_page: 100 };
        const request = await this.getAxiosInstance();
        const results = await request(`clubs/${clubId}/activities`, { params });
        for (const activity of results.data) {
            activity.distance_miles = (activity.distance / 1609.344);
            activity.average_pace = activity.moving_time / activity.distance_miles / 60;
            activity.athlete_name = activity.athlete.firstname + ' ' + activity.athlete.lastname;
        }
        console.log(`Returning ${results.data.length} activities for page ${page}`);
        return results.data;
    }

    async getClubMembers(clubId: number) {
        try {
            console.log('getClubMembers...');
            const request = await this.getAxiosInstance();
            const results = await request(`clubs/${clubId}/members`);
            console.log(results.data);
            return results.data;
        } catch (error) {
            console.log('getClubMembers catch...');
            console.log(error);
        }
    }

    async getActivities(startDate?: string, endDate?: string, user?: User): Promise<Activity[]> {
        const PAGE_SIZE = 100;  // The number of activities to retrieve for each call
        const MAX_PAGES = 10; // This is just took keep things from carried away.  Set it larger than needed but not too large
        console.log('Starting getActivities...');
        console.log(startDate);
        let activities: Activity[] = [];
        const params: GetActivitiesParams = { per_page: PAGE_SIZE };
        if (startDate) {
            const date = new Date(startDate);
            params.after = Math.floor(date.getTime() / 1000);
        }
        if (endDate) {
            const date = new Date(endDate);
            params.before = Math.floor(date.getTime() / 1000);
        }

        // continue to retrieve pages until an empty one is received
        let morePages = true;
        let page = 1;
        try {
            while (morePages) {
                let request: AxiosInstance;
                if (user) {
                    request = axios.create({
                        baseURL: stravaURL,
                        headers: { Authorization: 'Bearer ' + user.access_token }
                    });
                } else {
                    request = await this.getAxiosInstance();
                }
                params.page = page;
                page = page + 1;
                const response = await request.get('athlete/activities', { params });
                if (!Array.isArray(response.data)) {
                    // assume we are done if an array isn't returned
                    break;
                }

                const newActivities = response.data.filter((activity: Activity) => activity.type === "Run") as Activity[];
                console.log(`Just got ${newActivities.length} new activities in page ${page - 1} from strava`);
                for (const activity of newActivities) {
                    activity.distance_miles = (activity.distance / 1609.344);
                    activity.average_pace = ((1 / activity.average_speed) * (1609.344 / 60));
                    // console.log(`date ${activity.start_date_local} distance ${activity.distance_miles}`);
                    if (user) {
                        activity.athlete.firstname = user.firstname;
                        activity.athlete.lastname = user.lastname;
                    }
                    // Add the week of the year
                    activity.week = this.weekOfYear(activity.start_date);
                }
                activities = [...activities, ...newActivities];
                if (newActivities.length < 1 || page > MAX_PAGES) {
                    morePages = false;
                }
            }
        } catch (error) {
            return this.handleError(error);
        }
        return activities;
    }

    weekOfYear(dateInput: string) {
        const MONDAY = 1;
        const date = new Date(dateInput)
        const firstDay = new Date(date.getFullYear(), 0, 1);
        const days = (date.getTime() - firstDay.getTime()) / (24 * 60 * 60 * 1000);
        // Adjust to start weeks on Monday...the first week may be a partial
        const offset = this.realMod(firstDay.getDay() - MONDAY, 7);

        return Math.ceil((days + offset) / 7);
    }

    // Use this formula to convert the remainder operator in a modulus
    realMod(a: number, b: number) {
        return ((a % b) + b) % b;
    }


    // TODO:  Write permissions have not been set up yet
    async editActivity(activityId: number, param: string, newValue: any): Promise<void> {
        console.log(`Update ${param} of activity ${activityId} to ${newValue}.`);

        const request = await this.getAxiosInstance();
        const body = { [param]: newValue}
        const response = await request.put('activities/' + activityId, body);
        console.log(`New name after the call to Strava is ${response.data.name}`);


    }


    // https://www.strava.com/api/v3/activities/4173637532/streams?keys=time,distance,velocity_smooth,heartrate&key_by_type=true
    async getStreams(id: string): Promise<any> {
        console.log(`Starting getStreams for ID: ${id}`);
        const token = this.auth.getToken('access_token');
        axios.defaults.baseURL = stravaURL;
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        const params = {
            keys: 'time,distance,velocity_smooth,heartrate',
            key_by_type: 'true'
        };
        try {
            const response = await axios.get('activities/' + id + '/streams', { params });
            console.log(response);
            return this.formatStreamData(response.data);
        } catch (error) {
            return this.handleError(error);
        }
    }

    // output is an array of [time, pace, dist, heartRate]
    formatStreamData(streams): number[][] {
        const dist = this.getStream(streams, 'distance');
        const time = this.getStream(streams, 'time');
        const velocity = this.getStream(streams, 'velocity_smooth');
        const heartRate = this.getStream(streams, 'heartrate');

        const len = dist.length < time.length ? dist.length : time.length;

        const output: number[][] = [];
        const AVG_INTERVAL = 30;
        const MAX_PACE = 25; // Slower than this and you are just standing around
        const pace: number[] = [];
        for (let i = 1; i < len; i++) {
            const interval = i < AVG_INTERVAL ? i : AVG_INTERVAL;
            // sec/meter to min/mile  multiply by 1609.344/60
            const p = ((+time[i] - +time[i - interval]) / (+dist[i] - +dist[i - interval])) * (1609.344 / 60);
            pace[i] = p < MAX_PACE ? p : MAX_PACE;
            const distanceMiles = (+dist[i] / 1609.344).toFixed(2);
            output.push([+time[i] / 60, +pace[i].toFixed(2), +distanceMiles, heartRate[i]]);
            // tslint:disable-next-line: max-line-length
            // if (1 < i && i < 100) { console.log(`${time[i]}, ${dist[i]} delta ${(+time[i] - +time[i - 1]).toFixed(1)} ${(+dist[i] - +dist[i - 1]).toFixed(1)} pace ${pace[i].toFixed(1)} velocity ${velocity[i].toFixed(1)}`); }
        }

        console.log(output.slice(0, 10));

        return output;
    }

    getStream(streams, name: string): number[] {
        if (streams && streams[name]) {
            return streams[name].data;
        }
        return null;
    }

    handleError(error): any {
        // from the axios doc..
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.config);
            return error.response.data;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
        return 'Error making http request';
    }

}
