import { Component, OnInit } from '@angular/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

export interface ChartData {
    labels: string[],
    data: number[],
    totalMiles: number
}

@Component({
  selector: 'app-club-charts',
  templateUrl: './club-charts.component.html',
  styleUrls: ['./club-charts.component.css']
})
export class ClubChartsComponent implements OnInit {
    data: ChartData;
    pieData: any;
    pieOptions: any;
    barData: any;
    barOptions: any;
    myPlugins = [ChartDataLabels];

  constructor(
      private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
      this.data = this.config.data.chartData;
      console.log(this.data);

      this.pieData = this.buildBarData(this.data);
      this.pieOptions = this.buildPieOptions(this.data);
      this.barData = this.buildBarData(this.data);
      this.barOptions = this.buildBarOptions(this.data);
    }

    buildBarData(data: ChartData) {
      // experimental sample
    // return {
    //     labels: ['EV', 'BA', 'BM', 'A4', 'A5', 'A6', 'A7','A8','A9','A10'],
    //     datasets: [{
    //         data: [16.1, 14.2, 9.3, 8.4, 7.5, 6.6, 4.7, 3.8, 2.9, 2.1],
    //         // color pallet from https://coolors.co/palettes/trending
    //         // backgroundColor: ['#033270' ,'#f29479' ,'#1368aa' ,'#f26a4f', '#4091c9', '#ef3c2d', '#9dcee2', '#cb1b16', '#fedfd4', '#65010c'],
    //         backgroundColor: ['#033270', '#1368aa', '#4091c9', '#9dcee2', '#fedfd4', '#f29479', '#f26a4f', '#ef3c2d', '#cb1b16', '#65010c'],

    //     }]
    // }

        // old data format
        // const labels = weekData.athletes.map((athlete) => athlete.initials);
        // const data = weekData.athletes.map((athlete) => athlete.totalMiles.toFixed(1));

        const formattedData = data.data.map((d) => d.toFixed(1));

        return {
            labels: data.labels,
            datasets: [{
                data: formattedData,
                backgroundColor: ['#033270', '#1368aa', '#4091c9', '#9dcee2', '#fedfd4', '#f29479', '#f26a4f', '#ef3c2d', '#cb1b16', '#65010c']
            }]
        }
    }

    buildPieOptions(data: ChartData) {
        const milesFormatted = data.totalMiles.toFixed(1);
        return {
            plugins: {
                title: {
                    display: true,
                    text: `Total miles ${milesFormatted}`
                },
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                },
                datalabels: {
                    display: true,
                    anchor: 'end',
                    align: 'start',
                    color: 'yellow',
                    padding: 6,
                    labels: {
                        title: {
                            font: {
                                weight: 'bold'
                            },
                            formatter: function(value, context) {
                                return `${context.chart.data.labels[context.dataIndex]}\n${value}`;
                            }
                        }
                    }
                }
            }
        }
    }

    buildBarOptions(data: ChartData) {
        const milesFormatted = data.totalMiles.toFixed(1);
        return {
            plugins: {
                title: {
                    display: true,
                    text: `Total miles ${milesFormatted}`
                },
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                },
                datalabels: {
                    display: true,
                    anchor: 'end',
                    align: 'end',
                }
            }
        }
    }

}
