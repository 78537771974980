<mat-card class="chart-card">
    <mat-card-header fxLayout="row">
        <app-athlete-header [title]="'Activities'"></app-athlete-header>
        <div fxFlex></div>
        <app-time-frame-selector></app-time-frame-selector>
    </mat-card-header>

    <div echarts [options]="overviewOptions" (chartClick)="onStravaChartClick($event)"></div>
</mat-card>

<mat-card class="chart-card">
    <div echarts [options]="singleRunOptions"></div>
</mat-card>
<!-- <h1>ECharts will go here once I get them working</h1> -->
