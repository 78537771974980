import { Component, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { DateRange, TimeFrame } from '../services/models/date-range';
import { StravaRxjsService } from '../services/strava-rxjs.service';


@Component({
  selector: 'app-time-frame-selector',
  templateUrl: './time-frame-selector.component.html',
  styleUrls: ['./time-frame-selector.component.css']
})
export class TimeFrameSelectorComponent implements OnInit {
    timeFrame = TimeFrame;
    selectedTimeFrame$ = this.stravaRxjs.selectedTimeFrameAction$.pipe(
        map(range => range.label),
    );

  constructor(private stravaRxjs: StravaRxjsService) { }

  ngOnInit(): void {
  }


selectTimeFrame(timeFrame: TimeFrame): void {
    const date = new Date(Date.now());
    const day = date.getDay();
    const shift = (day + 7 - 1) % 7; // number of days to subtract to get to previous Monday
    let startDate: string;
    let endDate: string;
    // Set up a default endDate for the end of the current day
    date.setHours(23);
    endDate = date.toISOString();
    date.setHours(1);
    switch (timeFrame) {
        case TimeFrame.ThisMonth:
            date.setDate(1);  // set to the beginning of the current month
            startDate = date.toISOString();
            break;
        case TimeFrame.LastMonth:
            date.setDate(1);  // set to the beginning of the current month
            endDate = date.toISOString();
            date.setMonth(date.getMonth() - 1);
            startDate = date.toISOString();
            break;
        case TimeFrame.ThisWeek:
            date.setDate(date.getDate() - shift);
            startDate = date.toISOString();
            break;
        case TimeFrame.LastWeek:
            date.setDate(date.getDate() - shift);
            endDate = date.toISOString();
            date.setDate(date.getDate() - 7);
            startDate = date.toISOString();
            break;
        case TimeFrame.ThisYear:
            date.setDate(1);  // set to the first day of the current year
            date.setMonth(0);
            startDate = date.toISOString();
            break;
        case TimeFrame.LastYear:
            date.setDate(1);  // set to the first day of the current year
            date.setMonth(0);
            endDate = date.toISOString();
            date.setFullYear(date.getFullYear() - 1);
            startDate = date.toISOString();
            break;
        default:
            date.setDate(1);  // set to the beginning of the current month
            break;
    }

    this.stravaRxjs.setTimeFrame({
        label: timeFrame,
        startDate: startDate,
        endDate: endDate
    })
}


}
