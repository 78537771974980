import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-strava-login',
  templateUrl: './strava-login.component.html',
  styleUrls: ['./strava-login.component.css']
})
export class StravaLoginComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    const url = this.route.snapshot.url;
    console.log(url);
    if (url && url[0] && url[0].path === 'strava_redirect') {
      const redirectParams = this.route.snapshot.queryParams;
      console.log(redirectParams);
      const scope = redirectParams.scope as string;
      const index = scope.indexOf('activity:read_all');
      if (index === -1) {
        console.log('Read all scope not included');
        // do an alert or something...
      }
      try {
        await this.auth.exchangeTokens(redirectParams.code);
        this.router.navigate(['']);
      } catch (error) {
        console.log('Error getting tokens...');
        console.log(error);
      }
    }

  }

  login(): void {
    // redirect to Strava site
    this.auth.initiateStravaAuth();
  }

}
