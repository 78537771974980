<!-- <mat-sidenav-container *ngIf="connectedToStrava"  class="sidenav-container"> -->
<mat-sidenav-container class="sidenav-container">
        <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"
        (click)="conditionallyClose(drawer)"
        >
        <mat-toolbar>Menu</mat-toolbar>
        <mat-nav-list>
            <a mat-list-item routerLink="club_details" routerLinkActive="active">Club Activities</a>
            <mat-divider></mat-divider>
            <h3 matSubheader>Individual Results</h3>
            <a mat-list-item routerLink="overview" routerLinkActive="active">Overview</a>
            <a mat-list-item routerLink="yearly_total" routerLinkActive="active">Yearly total</a>
            <a mat-list-item routerLink="run_details" routerLinkActive="active">Run Details</a>
            <!-- <a mat-list-item href="#">Link 3</a> -->
            <a mat-list-item><amplify-sign-out></amplify-sign-out></a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span>Run-Insights</span>
            <span class="flex-spacer"></span>
            <span><img src="../../assets/api_logo_pwrdBy_strava_horiz_white.png"
                alt="Strava Powered By logo"
                style="height: 25px;"></span>
        </mat-toolbar>
        <!-- Add Content Here -->
        <amplify-authenticator>
            <!-- previous code -->
            <router-outlet></router-outlet>
        </amplify-authenticator>
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- <mat-card *ngIf="!connectedToStrava">
    <amplify-authenticator>
        <img src="../../assets/btn_strava_connectwith_orange.png"
            alt="Connect with Strava" (click)="stravaConnect()">
    </amplify-authenticator>
</mat-card> -->
