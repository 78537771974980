export enum TimeFrame {
    ThisWeek,
    LastWeek,
    ThisMonth,
    LastMonth,
    ThisYear,
    LastYear
}

export interface DateRange {
    label?: TimeFrame;
    startDate?: string;
    endDate?: string
}
