<mat-card>
    <mat-card-header  fxLayout="row">
        <app-athlete-header [title]="'Totals'"></app-athlete-header>
        <div fxFlex></div>
    </mat-card-header>
    <div echarts [options]="progressOptions" (chartClick)="onStravaChartClick($event)"></div>
</mat-card>

<mat-card>
    <mat-card-header>
        primeng/chart.js version
    </mat-card-header>
    <div class="p-col-12 p-md-6">
        <p-chart type="bar" [data]="barData" [options]="barOptions" [plugins]="myPlugins"></p-chart>
    </div>
</mat-card>
