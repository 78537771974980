import { Component, OnDestroy, OnInit } from '@angular/core';
import { StravaRxjsService } from '../services/strava-rxjs.service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BarSeriesOption, EChartsOption, LineSeriesOption, number } from 'echarts';
import ChartDataLabels from 'chartjs-plugin-datalabels';

type ECOption = echarts.ComposeOption<
    | BarSeriesOption
    | LineSeriesOption
>;

interface MONTHLY_TOTALS {month: string; miles: number; };

@Component({
  selector: 'app-yearly-total',
  templateUrl: './yearly-total.component.html',
  styleUrls: ['./yearly-total.component.css']
})
export class YearlyTotalComponent implements OnInit, OnDestroy {
    progressOptions: ECOption;
    subscriptions: Subscription[] = [];
    activities: any;
    monthlyTotals: MONTHLY_TOTALS[];
    totalMiles: number;
    myPlugins = [ChartDataLabels];
    barData: { labels: string[]; datasets: { data: string[]; }[]; };
    barOptions: { plugins: { title: { display: boolean; text: string; }; legend: { display: boolean; }; tooltip: { enabled: boolean; }; datalabels: { display: boolean; anchor: string; align: string; }; }; };


    athlete$ = this.stravaRxjs.athlete$.pipe(
        tap(athlete => console.log(athlete)),
        catchError(err => {
            console.log(err ? err : 'error getting athlete');
            return EMPTY
        })
    )

    constructor(
        private stravaRxjs: StravaRxjsService
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(this.stravaRxjs.activitiesMax$.subscribe(activities => {
            if (activities && Array.isArray(activities)) {
                this.activities = activities;
                this.monthlyTotals = this.tallyByMonth(activities);
                this.totalMiles = this.calculateTotalMiles(this.monthlyTotals)
                this.buildProgressOptions();
                this.barData = this.buildBarData(this.monthlyTotals);
                this.barOptions = this.buildBarOptions(this.monthlyTotals);
            }

        }))
    }

    calculateTotalMiles(monthlyTotals: MONTHLY_TOTALS[]): number {
        let total = 0;
        monthlyTotals.forEach(m => total += m.miles);
        return total;
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe())
    }

    tallyByMonth(activities): MONTHLY_TOTALS[] {
        let result: MONTHLY_TOTALS[] = [];
        let accumulator: {[month: string]: {month: string; miles: number;}};
        activities.forEach(activity => {
            if (activity.sport_type === "Run") {
                let month = activity.start_date_local.slice(0,7);
                if (!accumulator) {
                    accumulator = {
                        [month]: {
                            'month': month,
                            'miles': activity.distance_miles
                        }
                    }
                }
                else if (accumulator.hasOwnProperty(month)) {
                    accumulator[month].miles += activity.distance_miles;
                } else {
                    accumulator[month] = {month: month, miles: activity.distance_miles}
                }
            }
        });
        for (const key in accumulator) {
            if (Object.prototype.hasOwnProperty.call(accumulator, key)) {
                const element = accumulator[key];
                result.push(element);
            }
        }

        const d = new Date();
        d.setMonth(d.getMonth() - 1);  // Want to use the last full month
        const endMonth = d.toISOString().slice(0,7);
        d.setMonth(d.getMonth() - 11);
        const startMonth = d.toISOString().slice(0,7);
        const filteredResult = result.filter(record => {
            return record.month >= startMonth && record.month <= endMonth;
        });
        filteredResult.sort((a, b) => {
            if (a.month < b.month) {
                return -1;
            }
            if (a.month > b.month) {
                return 1;
            }
            return 0;
        });

        return filteredResult;
    }

    buildProgressOptions() {
        this.progressOptions = {
            title: {
                show: true,
                text: 'Last 12 Month Progress',
                subtext: `Total miles ${this.totalMiles.toFixed(1)}`
            },
            legend: {},
            tooltip: {
                trigger: 'axis',
                valueFormatter: (value) => value.toFixed(2)
            },
            dataset: {
                dimensions: ['month', 'miles'],
                source: this.monthlyTotals
            },
            xAxis: {
                type: 'time'
            },
            yAxis: {},
            series: [
                {
                    type: 'bar',
                    name: 'Miles',
                    barMinWidth: 4,
                    emphasis: {

                        itemStyle: {
                            // barBorderWidth: 2,
                            // barBorderColor: '#888'
                        }
                    }
                },
            ],
        };
    }

    onStravaChartClick(event) {

    }

    buildBarOptions(data: MONTHLY_TOTALS[]) {
        return {
            plugins: {
                title: {
                    display: true,
                    text: `Total miles ${this.totalMiles.toFixed(1)}`
                },
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                },
                datalabels: {
                    display: true,
                    anchor: 'end',
                    align: 'end',
                }
            },
            scales: {
                x: {
                  title: {
                    color: 'black',
                    display: true,
                    text: 'Month'
                  }
                },
                y: {
                    title: {
                      color: 'black',
                      display: true,
                      text: 'Miles'
                    }
                  }

              }
        }
    }

    buildBarData(data: MONTHLY_TOTALS[]) {
    const formattedData = data.map(d => d.miles.toFixed(1));

    return {
        labels: data.map(d => d.month),
        datasets: [{
            data: formattedData,
            backgroundColor: [ '#4091c9' ]
        }]
    }
}

}
