import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { StravaService } from '../services/strava.service';
import { parse } from 'json2csv';
import { saveAs } from 'file-saver';
import { AuthService } from '../services/auth.service';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Message } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ClubChartsComponent, ChartData } from '../club-charts/club-charts.component';
import { Activity } from '../services/models/activity';

interface Athlete {
    id: number;
    firstname: string;
    lastname: string;
    initials: string;
    totalMiles: number;
}

export interface WeekData {
    week: number;
    totalMiles: number;
    athletes: [
        Athlete
    ]
}

interface WeeklyTotal {
    weeks: [ WeekData ]
    overall?: {
        totalMiles: number;
        athletes: [
            Athlete
        ]
    }
}

interface Year {
    name: string;
    code: number;
}

interface MatrixColumns {
    header: string;
    field: string;
    totalMiles: number;
}

@Component({
    selector: 'app-club-details',
    templateUrl: './club-details.component.html',
    styleUrls: ['./club-details.component.css'],
    providers: [ DialogService ]
})
export class ClubDetailsComponent implements OnInit, AfterViewInit {
    stravaUrl = "https://www.strava.com";

    useMat = false;
    usePrime = true;
    loadingData = true;
    loadError = false;
    validYears: Year[];
    selectedYear: number;
    clubActivities: Activity[];
    weeklyTotal: WeeklyTotal;
    matrixColumns: MatrixColumns[];
    matrixView: any[];
    displayColumns = ['athlete', 'start_date', /*'name',*/ 'distance', 'time', 'pace', 'elevation'];
    dataSource: MatTableDataSource<Activity>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    multiSortMeta = [
        { field: 'week', order: -1 },
        { field: 'start_date', order: -1 }
    ]

    displayMessages: Message[];

    progress = 0;
    goal = 1000;

    constructor(
        private strava: StravaService,
        private auth: AuthService,
        private dialogService: DialogService
    ) {
        this.clubActivities = [];
        this.dataSource = new MatTableDataSource<Activity>(this.clubActivities);
    }

    async ngOnInit(): Promise<void> {
        // Get Club activities from the users id...only provides partial information
        // const id = await this.strava.getClubId('The Running Crew');
        // for (let page = 1; page < 5; page++) {
        //     const results = await this.strava.getClubActivities(id, page);
        //     this.clubActivities = this.clubActivities.concat(results);
        // }

        const date = new Date();
        const currentYear = date.getFullYear();
        this.selectedYear = currentYear;
        this.validYears = [];
        for (let year = currentYear; year >= 2020; year--) {
            this.validYears.push({ name: year.toString(), code: year });
        }

        // Get Club activities using each members access token to get everything
        await this.buildActivityData(currentYear);

        // Uncomment the next line to get the limited information that is available without them logging in
        // this.getLimitedClubActivities();
    }

    ngAfterViewInit() {
        if (this.useMat) {
            this.dataSource.paginator = this.paginator;
            this.sort.sort(({ id: 'start_date', start: 'desc'}) as MatSortable);
            this.dataSource.sort = this.sort;
        }
    }

    async buildActivityData(year: number) {
        this.loadingData = true;
        try {
            this.clubActivities = await this.getFullMemberActivities(year);
            this.loadingData = false;
            if (this.clubActivities && Array.isArray(this.clubActivities) && this.clubActivities.length !== 0) {
                this.tallyWeeklyTotals(this.clubActivities);

                console.log(this.clubActivities);
                console.log(this.weeklyTotal);
                this.dataSource.data = this.clubActivities;

                this.matrixColumns = this.orderColumns(this.weeklyTotal.overall.athletes);
                this.matrixView = this.buildMatrix(this.weeklyTotal);

                this.progress = Math.round((this.weeklyTotal.overall.totalMiles / this.goal) * 100);
            }
        } catch (error) {
            // display an error message
            this.loadError = true;
            const msg = error.message ? error.message : error;
            this.displayMessages = [{ severity:'error', summary:'Error', detail: msg }]
            this.loadingData = false;
            console.log(error);
        }
    }

    async getLimitedClubActivities(): Promise<void> {
        let clubActivities = [];
        const id = await this.strava.getClubId('The Running Crew');
        let page = 1;
        const endTag = '1/23/2022';
        let foundIt = false;
        while (foundIt === false) {
            const results = await this.strava.getClubActivities(id, page);
            clubActivities = clubActivities.concat(results);
            const index = results.findIndex(e => {
                return e.name.includes(endTag);
            });
            if (index > -1 || results.length === 0 || page > 20) {
                foundIt = true;
            }
            page++;
        }
        console.log(`There are ${page - 1} pages and ${clubActivities.length} activities.`);
        console.log(clubActivities);
    }

    async downloadCSV() {
        const fields = ['week', 'athlete.firstname', 'athlete.lastname', 'start_date', 'name', 'distance_miles',
                        'moving_time', 'average_pace', 'average_heartrate', 'total_elevation_gain'];
        const opts = { fields };

        try {
            const csv = parse(this.clubActivities, opts);
            console.log(csv);
            const blob = new Blob([csv]);
            saveAs(blob, 'clubActivities.csv');
        } catch (err) {
            console.error(err);
        }
    }

    // TODO this function requires write permission and only read is provided
    async editName() {
        let startDate: string;
        let endDate: string;
        const date = new Date(Date.now());

        date.setDate(date.getDate() - 2);
        startDate = date.toISOString();
        date.setHours(1);

        const results: Activity[] = await this.strava.getActivities(startDate, endDate);

        for (const activity of results) {
            if (/\d/.test(activity.name)) {  // check to see if the name contains a number and assume it is a date
                continue;
            }

            const date = activity.start_date_local.split('T')[0];
            const newName = activity.name + ' ' + date;

            await this.strava.editActivity(activity.id, 'name', newName);
        }
    }

    async getFullMemberActivities(year: number) {
        let allActivities: Activity[] = [];
        const users = await this.auth.getAllTokensFromDb();
        if (!users || !Array.isArray(users) || users.length === 0) {
            return allActivities;
        }
        let startDate: string;
        let endDate: string;
        const date = new Date(Date.now());
        if (date.getFullYear() === year) {
            date.setHours(23);
            endDate = date.toISOString();
            date.setHours(1);

            // date.setDate(date.getDate() - 2);
            date.setDate(1);  // set to the first day of the current year
            date.setMonth(0);
            startDate = date.toISOString();
        } else {
            date.setHours(1);
            date.setDate(1);
            date.setMonth(0);
            date.setFullYear(year);
            startDate = date.toISOString();

            date.setMonth(11);
            date.setDate(31);
            date.setHours(23);
            endDate = date.toISOString();
        }

        const finishedUsers = [];
        for (const user of users) {
            if (finishedUsers.findIndex(u => u === user.stravaId) !== -1) {
                continue;
            }
            const results: Activity[] = await this.strava.getActivities(startDate, endDate, user);
            if (!results || !Array.isArray(results)) {
                throw results;
            }
            allActivities = [...allActivities, ...results];
            finishedUsers.push(user.stravaId);
        }

        allActivities.sort((a, b) => {
            return b.start_date > a.start_date ? 1 : -1;
        });

        return allActivities;
    }

    /**
     * Tally up the weekly totals for the given activities.
     *
     * @param {Activity[]} activities - An array of activities to tally.
     */
    tallyWeeklyTotals(activities: Activity[]) {
        this.weeklyTotal = null;
        for (const activity of activities) {
            if (this.weeklyTotal) {

                const week = this.weeklyTotal.weeks.find(w => w.week === activity.week);
                if (week) {
                    week.totalMiles += activity.distance_miles;
                    const index = week.athletes.findIndex(athlete => athlete.id === activity.athlete.id);
                    if (index > -1) {
                        week.athletes[index].totalMiles += activity.distance_miles;
                    } else {  // add a new athlete
                        week.athletes.push(this.newAthlete(activity))
                    }
                } else {  // add a new week
                    this.weeklyTotal.weeks.push({ week: activity.week, totalMiles: activity.distance_miles, athletes: [ this.newAthlete(activity)]});
                }

                this.weeklyTotal.overall.totalMiles += activity.distance_miles;
                const index = this.weeklyTotal.overall.athletes.findIndex(athlete => athlete.id === activity.athlete.id);
                if (index > -1) {
                    this.weeklyTotal.overall.athletes[index].totalMiles += activity.distance_miles;
                } else {  // add a new athlete
                    this.weeklyTotal.overall.athletes.push(this.newAthlete(activity));
                }
            } else {  // build the initial weeklyTotal object
                this.weeklyTotal = {
                    weeks: [{ week: activity.week, totalMiles: activity.distance_miles, athletes: [ this.newAthlete(activity)]}],
                    overall: {
                        totalMiles: activity.distance_miles,
                        athletes: [ this.newAthlete(activity) ]
                    }
                }
            }
        }
    }


    newAthlete(activity: Activity): Athlete {
        const initials = `${activity.athlete.firstname.charAt(0)}${activity.athlete.lastname.charAt(0)}`;
        return {...activity.athlete, totalMiles: activity.distance_miles, initials: initials}
    }

    getWeekTotal(weekNumber: number): number {
        const week = this.weeklyTotal?.weeks.find(w => w.week === weekNumber);
        return week ? week.totalMiles : 0;
    }

    orderColumns(athletes: Athlete[]): MatrixColumns[] {
        let overallTotal = 0;
        const col = [
            { field: 'week', header: 'Week', totalMiles: 0 }
        ]

        const sorted = [...athletes];
        sorted.sort((a, b) => b.totalMiles - a.totalMiles);
        for (let index = 0; index < sorted.length; index++) {
            const athlete = sorted[index];
            col.push( { field: `${athlete.id}_miles`, header: `${athlete.initials}`, totalMiles: athlete.totalMiles});
            overallTotal += athlete.totalMiles;
        }
        col.push({ field: 'totalMiles', header: 'Total', totalMiles: overallTotal });
        return col;
    }

    buildMatrix(weeklyTotal: WeeklyTotal) {
        const matrixView = [];
        for (const week of weeklyTotal.weeks) {
            const matrixWeek = { week: week.week };
            for (const athlete of week.athletes) {
                matrixWeek[`${athlete.id}_initials`] = athlete.initials;
                matrixWeek[`${athlete.id}_miles`] = athlete.totalMiles;
            }
            matrixWeek['totalMiles'] = week.totalMiles;
            matrixView.push(matrixWeek);
        }
        return matrixView;
    }

    showChart(rowData) {
        console.log(rowData);
        const chartData = this.decodeRowData(rowData);

        this.dialogService.open(ClubChartsComponent, {
            data: {
                chartData: chartData
            },
            header: `Week ${rowData.week}`,
            width: '90%'
        });
    }

    decodeRowData(rowData): ChartData {
        const weekData = this.weeklyTotal.weeks.find(w => w.week === rowData.week);
        weekData.athletes.sort((a, b) => {   // sort in descending order
            return b.totalMiles - a.totalMiles;
        });
        const labels = weekData.athletes.map((athlete) => athlete.initials);
        const data = weekData.athletes.map((athlete) => athlete.totalMiles);
        const totalMiles = weekData.totalMiles;
        const chartData = { labels, data, totalMiles };

        return chartData;
    }

    showTotalsChart() {
        const chartData = this.decodeTotalsData(this.matrixColumns);

        this.dialogService.open(ClubChartsComponent, {
            data: {
                chartData: chartData
            },
            header: `${this.selectedYear} Totals`,
            width: '90%'
        });
    }

    decodeTotalsData(matrixColumns: MatrixColumns[]): ChartData {
        let totalMiles: number;
        let data: number[] = [];
        let labels: string[] = [];

        for (const col of matrixColumns) {
            if (col.field === 'totalMiles') {
                totalMiles = col.totalMiles
            }
            if (col.field.includes('_miles')) {
                labels.push(col.header);
                data.push(col.totalMiles);
            }
        }

        return { labels, data, totalMiles };
    }

    changeYear() {
        this.buildActivityData(this.selectedYear);
    }
}
