// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// TODO: trying to use a process var is not working.  need to find another way to include env vars without putting them in code.
// const serviceUrl = process.env.RUNINSIGHTS_SERVICE_URL || 'http://localhost:3000/dev';
const serviceUrl = 'https://awzhors25j.execute-api.us-east-1.amazonaws.com/dev';

export const environment = {
  production: false,
  serviceUrl: serviceUrl
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
