/*
 * Copyright Eric Vaughn All Rights Reserved.
 *
 * SPDX-License-Identifier: Apache-2.0
*/

import axios from 'axios';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';

// const serviceUrl = process.env.RUNINSIGHTS_SERVICE_URL || 'http://localhost:3000/dev';
const serviceUrl = environment.serviceUrl;
const baseTokenName = 'Strava-';

type StravaAuthState = 'initializing' | 'finished';

export interface User {
    sub: string;
    user: string;
    firstname: string;
    lastname: string;
    stravaId: number;
    refreshToken: string;
    access_token?: string;
    profile_medium: string;
    profile: string;
    [propName: string]: any;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private stravaAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    stravaAuthenticated$ = this.stravaAuthenticatedSubject.asObservable();

    constructor() {
    }

    // Setup the default values for axios calls
    async setAxiosDefaults() {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        const jwt = idToken.getJwtToken();
        console.log('Cognito identityId', session.getIdToken());
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
        axios.defaults.baseURL = serviceUrl;
    }

    // Exchange the code for access and refresh tokens
    async exchangeTokens(code: string): Promise<void> {
        try {
            await this.setAxiosDefaults();
            const response = await axios.post('token_exchange', code);

            const data = response.data;
            const keys = Object.keys(data);
            for (const key of keys) {
                this.saveToken(key, data[key]);
            }
            this.stravaAuthenticatedSubject.next(true);
            console.log(data);
        } catch (error) {
            console.log('catch...');
            console.log(error);

            if (error.response && error.response.data) {
                console.log(error.response.data);
            }

            this.setStravaAuthState('finished');
            this.stravaAuthenticatedSubject.next(false);
            throw error;
        }
        this.setStravaAuthState('finished');
    }

    saveToken(name: string, token: string): void {
        localStorage.setItem(baseTokenName + name, token);
    }

    getToken(name: string): string {
        return localStorage.getItem(baseTokenName + name);
    }

    setStravaAuthState(state: StravaAuthState): void {
        localStorage.setItem(baseTokenName + 'AuthState', state);
    }

    getStravaAuthState(): StravaAuthState {
        return localStorage.getItem(baseTokenName + 'AuthState') as StravaAuthState;
    }

    /**
     * Returns whether the user is authenticated with Strava.
     * @returns {boolean} True if authenticated, false otherwise.
     */
    get stravaAuthenticated(): boolean {
        return this.stravaAuthenticatedSubject.value;
    }

    async checkStravaToken(): Promise<void> {
        if (this.getStravaAuthState() === 'initializing') {
            return;
        }
        const user = await Auth.currentAuthenticatedUser();
        if (!user) {
            console.log('User is not logged in.');
            return;
        }
        if (!this.getToken('refresh_token') ) {
            // check to see if refresh token is stored in DB
            const gotToken = await this.getTokenFromDb();
            if (gotToken) {
                await this.refreshTokens(this.getToken('refresh_token'));
            } else {
                // kick off Strava authentication
                this.initiateStravaAuth();
            }
        } else {
            const now = Math.floor(Date.now() / 1000);
            const expiresAt = this.getToken('expires_at');
            if (!expiresAt || (+expiresAt - now) < 3000) {  // should get a new token if less than an hour to go on old one
                await this.refreshTokens(this.getToken('refresh_token'));
            }
        }
    }

    async refreshTokens(refreshToken: string): Promise<void> {
        // call the lambda...
        try {
            await this.setAxiosDefaults();
            const response = await axios.post('token_refresh', refreshToken);

            const data = response.data;
            const keys = Object.keys(data);
            for (const key of keys) {
                this.saveToken(key, data[key]);
            }
            this.stravaAuthenticatedSubject.next(true);
            console.log(data);
        } catch (error) {
            console.log('catch...');
            const response = error.response;

            const data = response.data;
            this.stravaAuthenticatedSubject.next(false);
            console.log(data);
        }
    }

    async getTokenFromDb(): Promise<boolean> {
        // call the service lambda to read the DB for the refresh token
        try {
            await this.setAxiosDefaults();
            const response = await axios.get('get_refresh_token');

            this.saveToken('refresh_token', response.data);
            console.log(`Stored refresh token ${response.data} locally`);
            return true;
        } catch (error) {
            console.log('getTokenFromDb catch...');

            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            } else if (error && error.response) {
                console.log(error.response);
            } else if (error ) {
                console.log(error);
            }
            return false;
        }
    }

    async getAllTokensFromDb(): Promise<User[]> {
        // call the service lambda to read the DB for all users refresh token
        try {
            await this.setAxiosDefaults();
            const response = await axios.get('get_all_refresh_tokens');

            const users = response.data as User[];

            for (const user of users) {
                const resp = await axios.post('token_refresh', user.refreshToken);
                console.log(`New tokens for ${user.firstname} ${user.lastname}`);
                console.log(resp.data);
                const keys = Object.keys(resp.data);
                for (const key of keys) {
                    user[key] = resp.data[key];
                }
            }
            console.log(`User table from DB with access tokens`, users);
            return users;
        } catch (error) {
            console.log('getAllTokensFromDb catch...');
            let decodedError: any;

            if (error && error.response && error.response.data) {
                decodedError = error.response.data;
            } else if (error && error.response) {
                decodedError = error.response;
            } else if (error ) {
                decodedError = error;
            }

            console.log(decodedError);
            throw decodedError;
        }
    }

    initiateStravaAuth(): void {
        if (this.getStravaAuthState() === 'initializing') {
            return;
        }
        this.setStravaAuthState('initializing');
        const params = new HttpParams()
            .set('response_type', 'code')
            .set('client_id', '54757')
            .set('redirect_uri', `${window.location.origin}/strava_redirect`)
            .set('approval_prompt', 'force')
            .set('scope', 'activity:read_all,profile:read_all,read_all')
            .set('state', '1');
        const stravaAuthUrl = 'https://www.strava.com/api/v3/oauth/authorize';

        window.location.href = stravaAuthUrl + '?' + params.toString();
    }

}
