import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject, combineLatest, EMPTY, from, of, scheduled } from 'rxjs';
import { concatMap, expand, filter, map, mergeMap, scan, shareReplay, switchMap, take, tap, toArray } from 'rxjs/operators';
import { Activity, GetActivitiesParams } from './models/activity';
import { Athlete } from './models/athlete';
import { DateRange, TimeFrame } from './models/date-range';

const stravaURL = 'https://www.strava.com/api/v3/';
const PAGE_SIZE = 100;  // The number of activities to retrieve for each call
const MAX_PAGES = 10; // This is just took keep things from carried away.  Set it larger than needed but not too large


@Injectable({
    providedIn: 'root'
})
export class StravaRxjsService {

    token$ = from(this.auth.checkStravaToken()).pipe(
        map(() => this.auth.getToken('access_token')),
        tap(token => console.log(`the token is ${token}`)),
        shareReplay(1, 600 * 1000)  //600 * 1000 is ten minutes
    );

    athlete$ = this.token$.pipe(
        mergeMap(token => {


            const httpOptions = {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${token}`
                })
            };
            return this.http.get<Athlete>(`${stravaURL}athlete`, httpOptions)
        })
    );

    getTwoYearRange(): DateRange {
        const date = new Date();
        date.setHours(23);
        const endDate = date.toISOString();

        date.setDate(1);  // set to the first day of the current year
        date.setMonth(0);
        date.setFullYear(date.getFullYear() - 1); // subtract a year
        const startDate = date.toISOString();

        return { startDate, endDate };
    }
    private activitySubject = new BehaviorSubject<Activity[]>([]);
    activities$ = this.activitySubject.asObservable().pipe(
        scan((acc, value) => [...acc, ...value]),
        shareReplay(1)
    );

    // get 2 years worth of activities -- needs the expand operator to get all pages
    activitiesMax$ = this.token$.pipe(
        mergeMap(token => {
            /* first shot at getting 2 years worth of activities
            let params = new HttpParams().set('per_page', PAGE_SIZE);

            let date = new Date();
            date.setHours(23);
            params = params.set('before', Math.floor(date.getTime() / 1000));

            date.setDate(1);  // set to the first day of the current year
            date.setMonth(0);
            date.setFullYear(date.getFullYear() - 1); // subtract a year
            params = params.set('after', Math.floor(date.getTime() / 1000));

            const httpOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${token}`}),
                params: params
            };
            return this.http.get<Activity[]>(`${stravaURL}athlete/activities`, httpOptions)
            */
           let range = this.getTwoYearRange();
           let page = 1;
           return this.activitiesPageByRange$(token, range, page).pipe(
                map((activities) => {
                    let modifiedActivities = activities.map(activity => {
                        activity.distance_miles = (activity.distance / 1609.344);
                        activity.average_pace = ((1 / activity.average_speed) * (1609.344 / 60));
                        return activity;
                    });

                    return {
                        activities: modifiedActivities,
                        nextPage: page + 1,
                        token,
                        range
                    };
                })
            );
        }),
        expand((payload) => {
            if (payload.nextPage >= MAX_PAGES || payload.activities.length === 0) {
                return EMPTY
            }
            return this.activitiesPageByRange$(payload.token, payload.range, payload.nextPage).pipe(
                map((activities) => {
                    let modifiedActivities = activities.map(activity => {
                        activity.distance_miles = (activity.distance / 1609.344);
                        activity.average_pace = ((1 / activity.average_speed) * (1609.344 / 60));
                        return activity;
                    });

                    return {
                        activities: modifiedActivities,
                        nextPage: payload.nextPage + 1,
                        token: payload.token,
                        range: payload.range
                    };
                }))
        }),
        tap(activities => {
            console.log(activities);
            this.activitySubject.next(activities.activities);
        }),
        concatMap(({ activities }) => activities),
        toArray(),

        shareReplay(1)
    )

    private selectedTimeFrameSubject = new BehaviorSubject<DateRange>(this.getDefaultDateRange());
    selectedTimeFrameAction$ = this.selectedTimeFrameSubject.asObservable();

    activitiesPageByRange$ = ((token, range, page) => {
        console.log(`The page is ${page} in the mergeMap`)
        let params = new HttpParams().set('per_page', PAGE_SIZE);
        if (range.startDate) {
            const date = new Date(range.startDate);
            params = params.set('after', Math.floor(date.getTime() / 1000));
        }
        if (range.endDate) {
            const date = new Date(range.endDate);
            params = params.set('before', Math.floor(date.getTime() / 1000));
        }
        params = params.set('page', page);
        const httpOptions = {
            headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
            params: params
        }
        return this.http.get<Activity[]>(`${stravaURL}athlete/activities`, httpOptions);
    });

    activitiesByRange$ = combineLatest([
        this.selectedTimeFrameAction$,
        this.activities$
    ]).pipe(
        map(([range, activities]) => {
            return activities.filter(activity => activity.start_date > range.startDate && activity.start_date < range.endDate);
        }),
        tap(activities => console.log(activities))
    )

/* Second attempt that didn't work
    activitiesByRange$ = combineLatest([
        this.token$,
        this.selectedTimeFrameAction$
    ]).pipe(
        mergeMap(([token, range]) => {
            let page = 1;
            return this.activitiesPageByRange$(token, range, page).pipe(
                map((activities) => {
                    return {
                        activities: activities,
                        nextPage: page + 1,
                        token,
                        range
                    };
                })
            )
        }),
        expand((payload) => {
            if (payload.nextPage >= MAX_PAGES || payload.activities.length === 0) {
                return EMPTY
            }
            return this.activitiesPageByRange$(payload.token, payload.range, payload.nextPage).pipe(
                map((activities) => {
                    return {
                        activities: activities,
                        nextPage: payload.nextPage + 1,
                        token: payload.token,
                        range: payload.range
                    };
                }))

        }),
        // concatMap(({ activities }) => activities),
        tap((payload) => console.log(payload)),
        scan(( acc, payload ) => [...acc, ...payload.activities], [])
        // concatMap(({ activities }) => activities),
        // toArray(),
        // tap((activities) => console.log(activities))
    );
*/
    /* the initial attempts which didn't work completely
    activitiesByRange$ = of(1, 2, 3).pipe(concatMap(page => {
        console.log(`The page is ${page}`)
        return combineLatest([
            this.token$,
            this.selectedTimeFrameAction$
        ]).pipe(
            mergeMap(([token, range]) => {
                // let page = 1;
                console.log(`The page is ${page} in the mergeMap`)
                let params = new HttpParams().set('per_page', PAGE_SIZE);
                if (range.startDate) {
                    const date = new Date(range.startDate);
                    params = params.set('after', Math.floor(date.getTime() / 1000));
                }
                if (range.endDate) {
                    const date = new Date(range.endDate);
                    params = params.set('before', Math.floor(date.getTime() / 1000));
                }
                params = params.set('page', page);
                const httpOptions = {
                    headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
                    params: params
                }
                return this.http.get<Activity[]>(`${stravaURL}athlete/activities`, httpOptions).pipe(
                    // this causes an endless loop that exceeds the rate limit
                    // expand(activities => {
                    //     if (!Array.isArray(activities || activities.length < 1 || page > MAX_PAGES) ) {
                    //         return EMPTY
                    //     } else {
                    //         params = params.set('page', ++page)
                    //         return this.http.get<Activity[]>(`${stravaURL}athlete/activities`, httpOptions)
                    //     }
                    // })
                )
            }),
            map(activities => activities.map(activity => {
                activity.distance_miles = (activity.distance / 1609.344);
                activity.average_pace = ((1 / activity.average_speed) * (1609.344 / 60));
                return activity;
            }),
            ),
            // scan((activities: Activity[], newActivities: Activity[]) => [...activities, ...newActivities], [])
        )

    }
    ))
    */

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) { }

    setTimeFrame(range: DateRange) {
        this.selectedTimeFrameSubject.next(range);
    }

    getDefaultDateRange(): DateRange {
        const dr: DateRange = {label: TimeFrame.ThisWeek};

        const date = new Date(Date.now());
        date.setHours(23);
        dr.endDate = date.toISOString();

        const day = date.getDay();
        const shift = (day + 7 - 1) % 7; // number of days to subtract to get to previous Monday

        date.setHours(1);
        date.setDate(date.getDate() - shift);
        dr.startDate = date.toISOString();

        return dr;
    }
}
